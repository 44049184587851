import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\allReviews.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\ProductCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\ShippingOfferModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\VideoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\next\\dist\\client\\link.js");
