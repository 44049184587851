import { useState, useEffect } from "react";
import { fetchCurrencyRates } from "../currency";
import Cookies from "js-cookie";

export const useCurrency = () => {
  const [currencyRates, setCurrencyRates] = useState<any>(null);
  const [convertedPrices, setConvertedPrices] = useState<{
    [key: string]: number;
  }>({});
  const [selectedCurrency, setSelectedCurrency] = useState<string>("EUR");

  useEffect(() => {
    // Check cookies for saved currency (if any)
    const savedCurrency = Cookies.get("selectedCurrency");
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency);
    }

    const loadCurrencyData = async () => {
      const rates = await fetchCurrencyRates();
      setCurrencyRates(rates);
    };

    loadCurrencyData();
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      Cookies.set("selectedCurrency", selectedCurrency, { expires: 365 });
    }
  }, [selectedCurrency]);

  const convertPrice = (price: number, from: string, to: string) => {
    if (!currencyRates || !currencyRates[to] || !currencyRates[from]) {
      return price;
    }
    const conversionRate =
      currencyRates[to]?.value / currencyRates[from]?.value;
    return price * conversionRate;
  };

  return {
    currencyRates,
    convertedPrices,
    selectedCurrency,
    setSelectedCurrency,
    convertPrice,
  };
};
