export const getCollections = async () => {
  try {
    const collections = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/collections`
    );

    if (!collections.ok) {
      throw new Error(`HTTP error! status: ${collections.status}`);
    }

    return await collections.json();
  } catch (error) {
    console.error("Failed to fetch collections:", error);
    return [];
  }
};

export const getCollectionDetails = async (collectionId: string) => {
  const collection = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/collections/${collectionId}`
  );
  return await collection.json();
};

export const getProducts = async () => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/products`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch products:", error);
    return [];
  }
};

export const getProductDetails = async (productId: string) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/products/${encodeURIComponent(
        productId
      )}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    return null;
  }
};
export const getSearchedProducts = async (query: string) => {
  const searchedProducts = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/search/${query}`
  );
  return await searchedProducts.json();
};

export const getOrders = async (customerId: string) => {
  const orders = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/customers/${customerId}`
  );
  return await orders.json();
};

export const getRelatedProducts = async (productId: string) => {
  const relatedProducts = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/products/${productId}/related`
  );
  return await relatedProducts.json();
};

export const getReviews = async (productId: string) => {
  if (!productId) {
    throw new Error("Product ID is required to fetch reviews");
  }

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/review?productId=${productId}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return [];
  }
};

export const getAllReviews = async () => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/reviews`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reviews = await response.json();
    return reviews;
  } catch (error) {
    console.error("Error fetching all product reviews:", error);
    return [];
  }
};
